import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import LoginPage from './components/Auth/Login';
import LogoutPage from './components/Auth/Logout';
import RegisterPage from './components/Auth/Register';
import Navbar from './components/Common/Navbar';
import Home from './components/Home/Home';
import InstacleanKpi from './components/InstacleanKpi/InstacleanKpi';
import RenderMaster from './components/RenderMaster/RenderMaster';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import VerifyToken from './components/Home/VerifyToken';
import RedirectError from './components/Home/RedirectError';

function App() {
  useEffect(() => {
    document.title = 'Internal Tool';
  }, []);

  return (
    <div className='App'>
      <Navbar />
      <Routes>
        <Route path='/' element={<ProtectedRoute element={<Home />} />} />
        <Route path='/verify-token' element={<PublicRoute element={<VerifyToken />} />} />
        <Route path='/invalid-login' element={<PublicRoute element={<RedirectError />} />} />
        <Route path='/login' element={<PublicRoute element={<LoginPage />} />} />
        <Route path='/logout' element={<ProtectedRoute element={<LogoutPage />} />} />
        <Route path='/register' element={<ProtectedRoute element={<RegisterPage />} />} />
        <Route path='/kpi' element={<ProtectedRoute element={<InstacleanKpi />} />} />
        <Route path='/render-master' element={<ProtectedRoute element={<RenderMaster />} />} />
      </Routes>
    </div>
  );
}

export default App;
