import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function RedirectError() {
  const handleRedirect = () => {
    window.location.href = '/';
  };

  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <div className='text-center'>
        <h1 className='display-4 text-danger'>Login Failed</h1>
        <p className='lead'>
          Unfortunately, the login process failed. This could be due to an invalid token or an issue
          with the backend process.
        </p>
        <div className='mt-4'>
          <button className='btn btn-primary btn-lg' onClick={handleRedirect}>
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
}

export default RedirectError;
