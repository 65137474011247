import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage() {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies(['access_token']);
  removeCookie('access_token');
  navigate('/login');
  return <></>;
}
