import React from 'react';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ListGroup from 'react-bootstrap/ListGroup';
import Pagination from 'react-bootstrap/Pagination';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import { Arrow90degUp } from 'react-bootstrap-icons';
import './RenderMasterSidePaneBrowser.css';

export default function SidePaneBrowser({
  breadcrumb,
  setBreadcrumb,
  dirs,
  files,
  setFileName,
  isLoading,
}) {
  function handleRootClick() {
    setBreadcrumb([]);
  }

  function handleBreadcrumbClick(index) {
    setBreadcrumb(breadcrumb.slice(0, index + 1));
  }

  function handleDirClick(prefix) {
    setBreadcrumb([...breadcrumb, prefix]);
  }

  async function handleFileClick(prefix) {
    setFileName(prefix);
  }

  return (
    <Card className='SidePaneBrowser'>
      {/* Breadcrumb */}
      <Card.Header className='SidePaneHeader'>
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleRootClick} active={isLoading}>
            Root
          </Breadcrumb.Item>
          {breadcrumb.map((prefix, index) => {
            return (
              <Breadcrumb.Item
                key={index}
                onClick={() => handleBreadcrumbClick(index)}
                active={index === breadcrumb.length - 1 || isLoading}
              >
                {prefix}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </Card.Header>

      {/* Dirs & File Listing */}
      <div className='SidePaneList'>
        {isLoading ? (
          <Spinner animation='grow' className='ListGroupSpinner' />
        ) : (
          <ListGroup className='ListGroup'>
            {dirs.map((dir, idx) => {
              return (
                <ListGroup.Item
                  className='SidePaneRow'
                  name={dir}
                  index={idx}
                  key={idx}
                  onClick={() => handleDirClick(dir)}
                >
                  {dir}
                </ListGroup.Item>
              );
            })}

            {files.map((file, idx) => {
              return (
                <OverlayTrigger
                  key={idx}
                  placement='right'
                  overlay={<Tooltip id='tooltip'>{file}</Tooltip>}
                >
                  <ListGroup.Item
                    className='SidePaneRow'
                    name={file}
                    index={idx}
                    onClick={() => handleFileClick(file)}
                  >
                    {file.substring(0, 12)}...
                    {file.slice(-12)}
                  </ListGroup.Item>
                </OverlayTrigger>
              );
            })}
          </ListGroup>
        )}
      </div>

      {/* Pagination buttons  */}
      <Card.Footer className='SidePaneFooter'>
        <Pagination className='SidePaneFooterPagination'>
          <Pagination.Prev />
          <Pagination.Item>
            <Arrow90degUp></Arrow90degUp>
          </Pagination.Item>
          <Pagination.Next />
        </Pagination>
      </Card.Footer>
    </Card>
  );
}
