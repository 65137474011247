import React from 'react';
import HomeCard from './HomeCard';
import './Home.css';

export default function Home() {
  return (
    <div className='Home'>
      <HomeCard title='KPI Dashboard' redirect='/kpi' />
      <HomeCard title='Render Master' redirect='/render-master' />
    </div>
  );
}
