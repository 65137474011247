import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './Login.css';
import { useState } from 'react';
import { googleLogin, login } from './api';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [, setCookie] = useCookies(['access_token']);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await login(email, password);
    if (response) {
      if (!response.error) {
        document.getElementById('login-error-msg').style.display = 'none';
        setCookie('access_token', response.accessToken, {
          path: '/',
          maxAge: 60 * 60 * 24 * 30,
        });
        navigate('/');
      } else {
        document.getElementById('login-error-msg').style.display = 'block';
      }
    }
  };

  return (
    <div className='LoginPage'>
      <Form className='LoginForm' onSubmit={(e) => handleSubmit(e)}>
        <h1>Login</h1>
        {/* <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text className='text-muted'>
            We will never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Form.Text className='text-danger' id='login-error-msg' style={{ display: 'none' }}>
            Invalid Credentials
          </Form.Text>
        </Form.Group>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                </Form.Group> */}{' '}
        {/* <Button variant='primary' type='submit'>
          Submit
        </Button> */}
        <Button
          variant='light'
          className='d-flex align-items-center p-2 shadow-sm'
          onClick={googleLogin}
        >
          <img
            src='https://static-00.iconduck.com/assets.00/google-icon-512x512-tqc9el3r.png'
            alt='Google Logo'
            style={{ width: '20px', marginRight: '10px' }}
          />
          <span className='ml-2' onClick={(e) => googleLogin()}>
            Sign in with Google
          </span>
        </Button>
      </Form>
      {/* <Button variant='outline' onClick={(e) => googleLogin()}>
        Google Sign-In
      </Button> */}
    </div>
  );
}
