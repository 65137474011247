import config from '../../config.js';
import axios from 'axios';

async function getDirsAndFiles(cookies, prefix = '', pageToken = '', fileKeyword = '') {
  const url = `${config.backendUrl}/render-master?prefix=${prefix}&page_token=${pageToken}&file_keyword=${fileKeyword}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
}

async function getFileContent(cookies, id) {
  const url = `${config.backendUrl}/render-master/${id}`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const response = await axios.get(url, axiosConfig);
  return response.data;
}

async function getPDF(cookies, html, height, width) {
  const url = `${config.backendUrl}/render-master/html-to-pdf`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
    responseType: 'blob',
  };
  const body = {
    html: html,
    height: height,
    width: width,
  };
  const response = await axios.post(url, body, axiosConfig);
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'receipt.pdf'); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export { getDirsAndFiles, getFileContent, getPDF };
