import React, { useRef } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, InputGroup, Form } from 'react-bootstrap';
import { Download, X, Clipboard, PlayFill } from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';
import { CSVLink } from 'react-csv';
import { getPDF } from './api';
import { highlighterHtml } from './htmlComponentHighlighter';
import './RenderMasterMainPane.css';

export default function Viewer({ fileContent }) {
  const [cookies] = useCookies(['access_token']);
  const [json, setJson] = useState(null);
  const [file, setFile] = useState(null);
  const [html, setHtml] = useState(null);
  const [customHtml, setCustomHtml] = useState(null);
  const [view, setView] = useState('editor');
  const [inputFields, setInputFields] = useState([]);
  const [numFields, setNumFields] = useState(0);
  const [csvName, setCsvName] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    let currentFile = fileContent.html;
    if (currentFile) {
      if (currentFile === '') {
        currentFile = '<html>Empty HTML</html>';
      } else if (typeof currentFile === 'boolean') {
        currentFile = '<html>Invalid HTML</html>';
      } else {
        setJson(fileContent);
        if (currentFile.search('</html>') === -1) {
          currentFile = currentFile.concat(highlighterHtml);
        } else {
          currentFile = currentFile.replace('</html>', highlighterHtml);
        }
      }
      const blob = new Blob([currentFile], { type: 'text/html' });
      setHtml(currentFile);
      setFile(URL.createObjectURL(blob));
    }
  }, [fileContent]);

  window.addEventListener('message', function (e) {
    const data = e.data;
    try {
      if (typeof data === 'string') {
        const decoded = JSON.parse(data);
        const blob = new Blob([decoded.doc], { type: 'text/html' });
        setHtml(decoded.doc);
        setFile(URL.createObjectURL(blob));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  });

  async function getJson() {
    if (json) {
      var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${fileContent.mid}.json`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }

  const htmlStringToPdf = async () => {
    let htmlString = html;
    const height = ref.current.scrollHeight;
    const width = ref.current.clientWidth;
    await getPDF(cookies, htmlString, height, width);
  };

  const copyHtmlToClipboard = async () => {
    navigator.clipboard.writeText(fileContent.html);
  };

  const handleCsvSubmit = (e) => {
    e.preventDefault();
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { Name: '', Description: '' };
    if (inputFields) {
      setInputFields([...inputFields, newfield]);
      setNumFields(numFields + 1);
    } else {
      setInputFields([newfield]);
      setNumFields(numFields + 1);
    }
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
    setNumFields(numFields - 1);
  };

  const compileHtml = () => {
    let html = customHtml || '';
    if (html.search('</html>') === -1) {
      html = html.concat(highlighterHtml);
    } else {
      html = html.replace('</html>', highlighterHtml);
    }
    const blob = new Blob([html], { type: 'text/html' });
    setFile(URL.createObjectURL(blob));
  };

  return (
    <div className='RenderMasterMainPane'>
      {/* HTML Iframe */}
      <div className='MainPaneIframeContainer'>
        <iframe className='MainPaneIframe' src={file} ref={ref} />
      </div>

      {/* JSON Viewer And Control Plane  */}
      <div className='MainPaneEditorContainer'>
        {/* View Selector */}
        <ButtonGroup className='d-flex'>
          <Button
            variant={view === 'editor' ? 'primary' : 'outline-primary'}
            onClick={() => setView('editor')}
          >
            JSON Data
          </Button>
          <Button
            variant={view === 'csv' ? 'primary' : 'outline-primary'}
            onClick={() => setView('csv')}
          >
            CSV Documentation
          </Button>
          <Button
            variant={view === 'html' ? 'primary' : 'outline-primary'}
            onClick={() => setView('html')}
          >
            Custom HTML
          </Button>
        </ButtonGroup>

        {view === 'editor' ? (
          <div className='EditorPane'>
            <br></br>
            <SyntaxHighlighter className='MainPaneEditor' language='json' style={vs}>
              {fileContent && JSON.stringify(fileContent, null, 2)}
            </SyntaxHighlighter>
            <ButtonGroup className='EditorPaneButtons'>
              <Button variant='outline-secondary' onClick={getJson}>
                JSON <Download />
              </Button>
              <Button variant='outline-secondary' onClick={htmlStringToPdf}>
                PDF <Download />
              </Button>
              <Button variant='outline-secondary' onClick={copyHtmlToClipboard}>
                HTML <Clipboard />
              </Button>
            </ButtonGroup>
          </div>
        ) : view === 'csv' ? (
          <div className='DocumentationPane'>
            <div className='FormApp'>
              <form onSubmit={handleCsvSubmit}>
                {inputFields?.map((input, index) => {
                  return (
                    <div key={index}>
                      <InputGroup className='mb-3' size='sm'>
                        <Form.Control
                          name='Name'
                          placeholder='Name'
                          value={input.Name}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <Form.Control
                          name='Description'
                          placeholder='Description'
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <Button variant='outline-secondary' onClick={() => removeFields(index)}>
                          <X size='20' color='red'></X>
                        </Button>
                      </InputGroup>
                    </div>
                  );
                })}
              </form>
              <Button variant='outline-secondary' size='sm' onClick={addFields}>
                Add Field
              </Button>
            </div>
            <br></br>
            <InputGroup size='sm' className='CsvDownloadForm'>
              <Form.Control placeholder='Enter CSV Name' id='csvname' />
              <Button variant='outline-secondary' size='sm'>
                <CSVLink
                  data={inputFields ? inputFields : [{ Name: '', Description: '' }]}
                  filename={csvName ? csvName : 'generated'}
                  onClick={() => {
                    setCsvName(document.getElementById('csvname').value);
                  }}
                  className='csvbutton'
                >
                  <Download />
                </CSVLink>
                &nbsp;
              </Button>
            </InputGroup>
          </div>
        ) : (
          <div className='HtmlPane'>
            <br></br>
            <div className='HtmlTextArea'>
              <InputGroup>
                <Form.Control
                  className='htmlTextArea'
                  as='textarea'
                  value={customHtml}
                  onChange={(e) => setCustomHtml(e.target.value)}
                />
              </InputGroup>
            </div>
            <ButtonGroup className='EditorPaneButtons'>
              <Button variant='outline-secondary' onClick={compileHtml}>
                Compile <PlayFill />
              </Button>
              <Button variant='outline-secondary' onClick={htmlStringToPdf}>
                PDF <Download />
              </Button>
            </ButtonGroup>
          </div>
        )}
      </div>
    </div>
  );
}
